@charset "UTF-8";

.card.shop-wizard {
    margin: 30px auto;
    float: none;

    .card-title {
        margin-bottom: 20px;
        color: $primary-color;
    }
  
    .card-action a {
        //font-weight: 400;
        color: $primary-color !important;
        text-transform: none !important;
    }

    .card-action a.capitalize {
        text-transform: capitalize !important;
    }

    .card-content {
        p {
            margin-bottom: $line-height/2;
        }

        label {
            color: initial;
        }

        .address-ul {
            padding-left: 35px;
        }

        td, th {
            padding: 0;
        }

        .plag-count {
            width: 120px;
            margin: $line-height/2 0;
        }
        
        a > i {
          position: relative;
          margin-left: 10px;
          top: 5px;
       }        
        
    } 

    .card-action {
      
        min-height: 80px;
        
        i {
            margin: -10px 0 0 0;
        }

        a:not(.btn):not(.btn-large):not(.btn-large):not(.btn-floating) {
            margin-right: 0;
        }

        a.back {
            margin-left: -10px;
        }

        a.next {
            float: right;
        }
        
        button
        {
            i {
              margin: 0;
            }
            text-transform: uppercase;
            font-weight: 400;
            padding-left: 10px;
            padding-right: 10px;
            min-width: 160px;
        }        
    }
}

.shop-wizard-nav {
    height: 80px;

    .breadcrumb {
        font-size: 1rem;
    }

    .breadcrumb:last-child {
        color: rgba(255, 255, 255, 0.7);
    }

    .breadcrumb.active {
        color: #fff;
        font-weight: 500;
    }
}

.shop-upload-form {
    border: 2px dashed #ccc;
    border-radius: 10px;
    padding: 0 10px;
    text-align: center;
    margin-top: -10px;
    margin-bottom: 15px;
    min-height: 135px;
    background-color: $alternate-color;

    &.dz-drag-hover,
    &:hover {
        cursor: pointer;
        border: 2px solid $secondary-color;
    }

    .uploadCircle {
        width: 120px !important;
        float: left;
        margin-top: -12px;
        margin-right:10px;

        strong {
            top: 40px;
        }
    }

    .dz-preview.dz-file-preview {
        position: relative;
    }

    .dz-details {
        text-align: left;
        margin-top: 25px;
    }

    .cancel-button {
        position: absolute;
        top: 0;
        right: 0;
    }

    .error-container {
        padding-top: 20px;

        i {
            vertical-align: bottom;
            font-size: $font-size * 1.5;
            margin-right: 5px;
        }

        .error-clear-btn {
            margin-top: $line-height;
        }
    }

    p {
        margin: $line-height/2 0;
    }

}

.modal-content {
  .dropdown-content {
    max-height: $line-height * 5;
  }
}

.shop-products {
    .shop-product {
        padding:10px;
        background-color: $alternate-color;
        text-align: center;
        p {
            &:first-child {
                font-weight: 200;
                font-size: 1.3rem;

            }
            &:nth-child(2) {
                font-size: 0.8rem;
            }
            &:nth-child(3) {
                font-weight: 500;
            }
            @extend .center-align;
            margin-bottom: 0px !important;
        }
    }
    .active {
        color:white !important;
        background-color: $primary-color !important;
    }
    .col {
        padding: 2px;
    }
}
