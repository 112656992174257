/* Switch
   ========================================================================== */

.switch,
.switch * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}

.switch label {
  cursor: pointer;
}

.switch label input[type=checkbox] {
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + .lever {
    background-color: $switch-checked-lever-bg;

    &:before, &:after {
      left: 18px;
    }

    &:after {
      background-color: $switch-bg-color;
    }
  }
}

.switch label .lever {
  content: "";
  display: inline-block;
  position: relative;
  width: 36px;
  height: 14px;
  background-color: $switch-unchecked-lever-bg;
  border-radius: $switch-radius;
  margin-right: 10px;
  transition: background 0.3s ease;
  vertical-align: middle;
  margin: 0 16px;

  &:before, &:after {
    content: "";
    position: absolute;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    left: 0;
    top: -3px;
    transition: left 0.3s ease, background .3s ease, box-shadow 0.1s ease, transform .1s ease;
  }

  &:before {
    background-color: transparentize($switch-bg-color, .85);
  }

  &:after {
    background-color: $switch-unchecked-bg;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
}

// Switch active style
input[type=checkbox]:checked:not(:disabled) ~ .lever:active::before,
input[type=checkbox]:checked:not(:disabled).tabbed:focus ~ .lever::before {
  transform: scale(2.4);
  background-color: transparentize($switch-bg-color, .85);
}

input[type=checkbox]:not(:disabled) ~ .lever:active:before,
input[type=checkbox]:not(:disabled).tabbed:focus ~ .lever::before {
  transform: scale(2.4);
  background-color: rgba(0,0,0,.08);
}

// Disabled Styles
.switch input[type=checkbox][disabled] + .lever {
  cursor: default;
  background-color: rgba(0,0,0,.12);
}

.switch label input[type=checkbox][disabled] + .lever:after,
.switch label input[type=checkbox][disabled]:checked + .lever:after {
  background-color: $input-disabled-solid-color;
}
