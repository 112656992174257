// ==========================================================================
// Plagaware variables
// ==========================================================================


// Colors
// ==========================================================================

// Mittelblau
//$primary-color: #3681bb;
$primary-color: #1369B2;  
 
// Hellblau
//$secondary-color: #cfd0fc;  // Banner-Hintergrund
$secondary-color: #1C85E0;  
//$secondary-color: #b9bbf1;  

// Dunkelblau (Logo)
//$thirdy-color: #000047; // Logo
$thirdy-color: #07345E;  

// Dunkelrot (Logo)
$emphasis-color: #8f0000; // Logo

$error-color: #ed212e;

$link-color: $primary-color;

$alternate-color: #eee; 

$gray-text-color: #666;


// Buttons
// ==========================================================================

$button-raised-background: $primary-color;    
$button-raised-background-hover: lighten($button-raised-background, 7%) !default;


// Typography
// ==========================================================================

$off-black: $thirdy-color;
$font-size: 16px;
$line-height: $font-size * 1.5;


// Navigation Bar
// ==========================================================================

$top-menu-height: $line-height * 1.5;

$navbar-height: $line-height * 3.5;
$navbar-line-height: $navbar-height - 4;
$navbar-height-mobile: $line-height * 3;
$navbar-line-height-mobile: $navbar-height-mobile;
$navbar-font-size: 1rem;
$navbar-font-color: #fff;
$navbar-brand-font-size: 2.1rem;

