@charset "UTF-8";

.row {
    margin-bottom: 0;
}

@media #{$large-and-up} {
  .container {
    width: 90%;
  }
}

@media #{$extra-large-and-up} {
  .container {
    width: 80%;
  }
}