// Common alignment

.floatleft
{
  float: left;
  margin-right: 20px;
}    

.floatright
{
  float: right;
  margin-left: 20px;
}    

.max640
{
  max-width:640px;
}

@media #{$large-and-up} 
{
  .floatleft60
  {
    float: left;
    margin-right: 20px;
    width: 60%;
  }    
  
  .floatright60
  {
    float: right;
    margin-left: 20px;
    width: 60%;
  }    
}



