@charset "UTF-8";

.navbar-fixed nav {
  top: 0;
}
 
nav {
  .button-collapse {
    color: $off-black;
    padding: 0 15px;
    margin: 0 0 0 -15px;
  }

  .user-menu {
    color: $off-black;
    float: right;
    padding: 0 15px;
    margin-right: -15px;

    i {
      font-size: 2rem;
    }
  }
  
  .plagaware-logo,
  .plagaware-logo-en {
    display: block;
    text-align: center;
    background-image: url('/gfx/content/mobile-logo.png');
    background-repeat: no-repeat;
    background-position: center;
    height: $navbar-height-mobile;
  } 
  
  .menu-list {
    margin-right: -15px;
    height: 80px;
    margin-bottom: 10px;
    & > li > a {
      color: $off-black;
      border-color: transparent;
      padding: 0;
      margin-right: 10px;
      border-bottom: 4px solid transparent;

      &:hover {
        border-bottom: 4px solid transparent;
        transition: border-color 0.5s;
        background-color: $alternate-color;
        color: $secondary-color;
      }
    }
  }

  .dropdown-button i {
    float: right;
  }

  .dropdown-content {

    li > a {
      color: $off-black;
      &:hover {
        background-color: #eee;
      }
    }
  }
}


@media #{$medium-and-up} {
  nav .plagaware-logo {
    background-image: url('/gfx/content/logo-de.png');
    height: $navbar-height;
  } 
}

@media #{$medium-and-up} {
  nav .plagaware-logo-en {
    background-image: url('/gfx/content/logo-en.png');
    height: $navbar-height;
  } 
}


@media #{$large-and-up} {
  nav .plagaware-logo,  
  nav .plagaware-logo-en {
    position: absolute;
    width: 245px;
    left: 0;
  }

  .user-menu {
    display: none;
  }
}

@media #{$extra-large-and-up} {
  nav .menu-list > li > a {
    margin-right: 15px;
  }
}

