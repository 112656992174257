@charset "UTF-8";

p {
	margin: $line-height 0;

}

a.underline:hover {
	text-decoration: underline;
}

a.nodeco:hover {
	text-decoration: none;
}

.primary-color {
	background-color: $primary-color;
}

.primary-color-text {
	color: $primary-color;
}

.secondary-color {
	background-color: $secondary-color;
}

.secondary-color-text {
	color: $secondary-color;
}

.thirdy-color {
	background-color: $thirdy-color;
}

.alternate-color {
	background-color: $alternate-color;
}

.thirdy-color-text {
	color: $thirdy-color;
}

.emphasis-color {
	background-color: $emphasis-color !important;
}

.emphasis-color-text {
	color: $emphasis-color !important;
}

.link-color {
	color: $link-color !important;
}

.full-width {
	width: 100%;
}

.submit-width {
	width: 250px;
}

.minHeight150 {
	min-height: 150px;
}

ul {
	margin: 0;

	&.inline-list > li {
		display: inline-block;
		padding-right: 20px;
	}
}

.clear-fix {
	clear: both;
}

header .contact-menu i.material-icons,
header .service-menu i.material-icons {
	line-height: $line-height * 1.5;
}

.side-nav {
	.collapsible-header {
		padding-left: 32px;
	}

	li > a > i.material-icons {
		margin-right: 10px;
	}

	li > a.collapsible-header > i {
		margin-right: 10px;
		float: right;
	}

	.collapsible-body li a {
		padding-left: 54px;
		font-weight: 400;
	}

	li.no-link {
		color: rgba(0, 0, 0, 0.87);
		display: block;
		font-size: 14px;
		font-weight: 500;
		height: $line-height * 2;
		line-height: $line-height * 2;
		padding: 0 32px;

		i {
			display: inline-block;
			margin-right: 10px;
			vertical-align: middle;
		}
	}
}

.avatar {
	margin-top: 5px;
	margin-right: 12px;
	float: left;
}

.top-menu {
	display: none;
	height: $top-menu-height;
	font-size: 0.9rem;
	background-image: url(/gfx/content/background-blue.jpg);
	background-position-y: 800px;


	& ul {
		margin: 0;
		line-height: $line-height * 1.5;
	}
}

.padding-section {
	padding-top: $line-height;
	padding-bottom: $line-height;
	.logo {
		display: flex;
  		justify-content: center;
  		align-items: center;
  		height: 100px;
	}
}

.circle-icon {
	@extend .circle;
	max-width: 128px;
}

.logo-section {
	h3 {
		color: $secondary-color;
	}
	.row {
		margin-bottom: 40px;
	}
}

.slider {
	.slides {
		background-color: transparent;

		li img {
			background-size: auto 100%;
			background-repeat: no-repeat;
		}

		li .caption {
			width: 100%;
			left: 0;
		}
	}
	.indicators {
		z-index: 2;

		.indicator-item {
			background-color: rgba(#fff, 0.5);
			bottom: 15px;

			&.active {
				background-color: rgba(#fff, 0.9);
			}
		}
	}
}

.payments-section {
	background-color: $thirdy-color;
	.inline-list {
		li {
			padding: 0 15px;
		}
	}
	img {
		border: 1px;
		border-radius: 3px;
		padding: 4px;
		background-color: #fff;
	}
	
}


.home-slider {
	.slider {
		background-color: $secondary-color;
		height: 500px !important;
		.slides {
			height: 100% !important;

			li {
				img {
					height: 45%;
					background-size: 100%;
					background-position: top center;
				}

				@for $i from 1 through 10 {
					&#slide-#{$i} img {
						background-image: url("/gfx/home-slider/mobile/slide-#{$i}.jpg") !important;
					}
				}
				.caption {
					top: 200px;

					.slide-header,
					.slide-sub {
						font-weight: 300;
						text-align: center;
					}

					.slide-header {
						font-size: 1.8rem;
						line-height: $line-height * 1.5;
						margin-top: $line-height * 1.75;
						margin-bottom: $line-height;
					}
					.slide-sub {
						font-size: 1.4rem;
						line-height: $line-height * 1.25;
						margin-top: 0px;
						margin-bottom: $line-height;
					}
				}
			}
		}
	}
}

.press-section {
	background-color: #ffffff;

	.slider {
		background-color: transparent;
		height: 350px !important;
		.slides {
			height: 100% !important;

			li {
				.caption {
					top: 0;

					.press-quote {
						color: $primary-color;
						font-size: 1.4rem;
						margin-bottom: 10px;
					}
				}

				img {
					height: 128px;
					background-repeat: no-repeat;
					background-size: initial;
					background-position: center;
				}
			}
		}
		.indicators {
			z-index: 2;

			.indicator-item {
				background-color: rgba($primary-color, 0.5);
				bottom: 0px;

				&.active {
					background-color: $primary-color;
				}
			}
		}
	}
}

.user-form-container {
	position: relative;
	width: 100%;
}

.user-form {
	text-align: center;

	.input-field {
		margin-top: 0;

		label {
			color: $secondary-color;
		}
	}

	input[type="password"],
	input[type="email"] {
		height: $line-height * 1.5;
		line-height: $line-height * 1.5;
		margin-top: 0;
		margin-bottom: $line-height * 1.5;
		border-bottom: 1px solid #fff;
	}

	.login-form {
		background-color: $primary-color;
		color: #fff;
		padding-top: $line-height;
		padding-bottom: $line-height * 2;

		.btn {
			margin-top: 0;
			margin-bottom: $line-height;
		}

		.forgot-password {
			display: block;
			color: #fff;
		}

		h3 {
			color: $primary-color;
		}

		ul.icon-list li {
			margin-top: 5px;
			margin-bottom: 5px;
		}
	}

	.register-form {
		padding-top: $line-height;
		padding-bottom: $line-height * 3;
	}

	.login-form,
	.register-form {
		padding-left: 5%;
		padding-right: 5%;
	}
}

.top-news-section {
	background-color: $thirdy-color;
	background-image: url(/gfx/content/background-blue.jpg);
	background-position-y: 690px;

	h2 {
		margin-bottom: 40px;
	}
}

.home-section {
	background-color: $thirdy-color;
	background-image: url(/gfx/content/background-blue.jpg);
	padding-bottom: 5px !important;

	h1 {
		margin-top: 40px;
		font-size: 3rem;
		line-height: 4.5rem;
		font-weight: 600;
	}

	.banner {
		padding-bottom: 80px;
		border-bottom: $primary-color 1px solid;
		margin-bottom: 30px;
	}

	.row {
		margin-top:50px;
		margin-bottom:30px;
	}

	.btn-flat {
		margin-top: 35px;
		width: 400px;
	}

	.made {
		text-align: right;
		margin-top: 0;
		margin-bottom: 0;
		margin-right: 5px;
		padding: 0;
		color: $secondary-color;
	}

}

span.badge {
	&.new {
		background-color: $emphasis-color;
	}
}

.btn,
.btn-flat {
	//text-transform: capitalize;
	height: $line-height * 2;
	line-height: $line-height * 2;

	&.white-border {
		border: 1px solid rgba(#fff, 0.5);

		&:hover {
			background-color: rgba(#fff, 0.2);
		}
	}
}

.waves-effect {
	&.waves-link-color .waves-ripple {
		background-color: lighten($link-color, 15%);
	}

	&.waves-link-color:hover {
		background-color: lighten($link-color, 21%);
	}
}

.features-section {
	background-color: $alternate-color;

	.features-text {
		margin-top: 0px;
		text-align: center;
	}

	a {
		//display: block; 
		text-align: center;
	}

	h3 {
		margin-top: 10px;
		margin-bottom: 0px;
	}
}

.all-news-section {
	padding-top: 0;
	padding-bottom: $line-height * 2;

	h2 {
		text-align: center;
	}

	h4 {
		text-transform: initial;
		margin-top: 0;
		margin-bottom: 0;
	}

	.col {
		margin-top: $line-height;
	}

	.news-date {
		display: inline-block;
		width: 60px;
		font-size: 1.2rem;
	}

	.news-text {
		padding-left: 60px;
		margin-left: 20px;
	}

	.news-mehr {
		display: block;
		color: $thirdy-color;
	}

	.weitere-news {
		display: block;
		color: $thirdy-color;
	}
}

.press-highlights-section, .home-section, .logo-section {
	padding-top: 24px;
	padding-bottom: 24px;

	.quote {
		font-weight: 400;
		font-size: 1.2rem;
	}
	.source {
		font-weight: 200;
		font-size: 0.9rem;
	}
}

blockquote {
	background-color: rgba(141, 193, 239, 0.267);
	margin: 10px;
	padding: 10px;
}

.welcome-section {
	background-image: url(/gfx/content/plagaware-welcome.jpg);
	background-position: center center;
}

.prices-section {
	background-color: $alternate-color;
	.price-header, .price {
		padding-top: 20px;
		font-size: 1.8rem;
	}
	.price {
		margin-bottom: 40px !important;
	}

}
.screenshot-section {
	margin-top: 12px;
	margin-bottom: 12px;
}

.choice-section {

	margin-top: 12px;
	margin-bottom: 12px;


	.row:nth-child(even) {
		background-color: $alternate-color;
		padding-top: 10px;
		padding-bottom: 10px;
		margin-top: 10px;
		margin-bottom:10px;
	}
}

.links-section {
	padding-top: $line-height;
	background-image: url(/gfx/content/background-blue.jpg);
	background-position: top left;

	.bottom-links a { 
		color: #fff;
	}

	.bottom-links a:hover {
		text-decoration: underline;
	}
}

.page-footer {
	padding: 0;
}

#btn-top {
	position: fixed;
	bottom: 80px;
	right: 10px;
	background-color: $emphasis-color;
}

#sidenav-overlay {
	z-index: 996;
}

@media #{$medium-only} {
	.user-form {
		text-align: left;
		position: relative;
		padding-bottom: $line-height * 4;
		padding-top: $line-height;
		padding-left: 7.5%;
		padding-right: 7.5%;
		background-color: $primary-color;

		.login-form {
			padding-top: 0;

			.forgot-password {
				position: absolute;
				bottom: $line-height * 2;
				color: #fff;
			}
		}

		.register-form {
			color: #fff;
			padding-top: 0;
			margin-left: 10%;

			.emphasis-color-text {
				color: #e0bcbc !important;
			}
		}

		.login-form,
		.register-form {
			width: 44%;
			display: inline-block;
			vertical-align: top;
			padding-left: 0;
			padding-right: 0;

			.btn {
				position: absolute;
				bottom: $line-height * 4;
				width: 37.5%;
				margin-bottom: 0;
			}
		}
	}
}

@media #{$medium-and-up} {
	.home-slider {
		.slider {
			background-color: $secondary-color;
			height: 360px !important;
			.slides {
				height: 100% !important;

				li {
					img {
						height: 100%;
						background-size: auto 100%;
						background-position: top center;
					}

					@for $i from 1 through 10 {
						&#slide-#{$i} img {
							background-image: url("/gfx/home-slider/large/slide-#{$i}.jpg") !important;
						}
					}

					.caption {
						top: 0;

						.slide-header,
						.slide-sub {
							font-weight: 300;
							width: 38%;
							text-align: left;
							text-shadow: 0px 0px 8px #000;
						}

						.slide-header {
							font-size: 1.8rem;
							line-height: $line-height * 1.75;
						}
						.slide-sub {
							font-size: 1.2rem;
							line-height: $line-height * 1.5;
						}
					}
				}
			}
		}
	}

	.features-section {
		h3 {
			height: $line-height * 2;
		}
	}

	.press-section .slider {
		height: 250px !important;
	}

	.footer-copyright-menu {
		float: left;
	}

	.footer-copyright-text {
		float: right;
	}
}

@media #{$large-and-up} {
	.top-menu {
		display: block;
	}

	.user-form {
		position: absolute;
		top: -330px;
		right: 0;
		width: 33%;
		min-width: 335px;
		background-color: transparent;
		padding: 0;
		text-align: left;
		z-index: 2;

		.container {
			width: 100%;
		}

		input[type="password"],
		input[type="email"] {
			border-bottom: 1px solid #9e9e9e;
			color: $off-black;
		}

		.input-field input,
		.input-field input:focus {
			& + label {
				color: #999 !important;
			}
		}

		.login-form,
		.register-form {
			transition: box-shadow 0.25s;
			padding: 0 $line-height $line-height;
			margin: 0 0 $line-height * 0.5;
			border-radius: 2px;
			background-color: #fff;
			box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
			0 3px 1px -2px rgba(0, 0, 0, 0.2);

			h3 {
				margin-top: 0;
				padding-top: $line-height;
			}

			&:hover {
				box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
			}
		}

		.login-form {
			color: $off-black;

			.forgot-password {
				color: $link-color;
			}
		}

		.register-form {
			background-color: #e0bcbc;
			color: #fff;
		}
	}

	.top-news-section {
		background-color: $primary-color;
		padding-bottom: $line-height * 2;
	}

	.all-news-section {
		padding-top: $line-height;

		.col {
			margin-bottom: 0;
		}

		.news-date {
			border-left: 1px solid #7575bd;
			padding: 0 0 0 20px;
		}

		.row > .col:first-child {
			.news-date {
				border: 0;
				padding: 0;
			}
			.news-text {
				margin: 0;
			}
		}

		.weitere-news {
			padding-left: 22px;
			border-left: 1px solid #7575bd;
		}
	}

	.features-section .feature-icon {
		margin-bottom: $line-height;
	}
}

////////////////////////////////////////////// Article ///////////////////////////////

.article-header {
	padding-top: $line-height;
	padding-bottom: $line-height;
	color: white;
	//background-color: $secondary-color;
	background-color: $primary-color;
	background-image: url(/gfx/content/background-blue.jpg);
	background-position: center 690px;

//	background-image: radial-gradient(circle farthest-side at center bottom, $secondary-color, $primary-color 125%);
min-height: 230px;

img {
	float: left;
	padding-right: 30px;
	padding-top: 0px;
}

p {
	font-size: $font-size * 1.2;
	line-height: $line-height * 1.5;
	font-style: italic;
	margin-bottom: 0;
}

h1 {
	margin-top: $line-height * 0.5;
	margin-bottom: $line-height * 0.75;
	color: white;
}

a {
	color: white;
	text-decoration: underline;
}
}

.articleimg {
	p {
		font-style: italic;
	}
}

article {
	h2 {
		color: $primary-color;
	}

	a {
		color: $secondary-color;
	}
	a:hover {
		text-decoration: underline;
	}

	// Icons in card links, e.g. /de/info/lizensierung-kosten
	a > i {
		position: relative;
		margin-right: 10px;
		top: 6px;
	}

	a.btn > i {
		top: 0px;
	}

	td > i.tooltipped {
		position: relative;
		margin-left: 10px;
		top: 5px;
		color: $primary-color;
		cursor: default;
	}

	.imagecard {

		.card-content {
			background-color: $alternate-color;
			color: $primary-color;
			font-style: italic;
			padding:20px;
		}

		.card-title {
			text-shadow: 0px 0px 8px $thirdy-color;
			line-height: $line-height * 1.2;
		}

		.card-box {
			background: rgba(0, 0, 71, 0.3);
			width: 100%;
		}

	

	}

	.download-card > .card-content > p {
		min-height: 120px;
	} 

	

	.card-action a {
		color: $emphasis-color !important;
	}

	.iconcard {
		img {
			padding: 25px;
		}
	}
}

////////////////////////////////// Lists

ul.icon-list:not(.browser-default) {
	li {
		padding-left: 40px;
		margin-bottom: $line-height;
		i {
			margin-left: -40px;
			float: left;
			color: $primary-color;
		}
	}
}

ul.related {
	list-style-image: url("/gfx/icons/flat/trending_flat.png");
	margin-left: 25px;
	li {
		padding-bottom: 10px;
	}
}

@media #{$medium-only} {
	.home-slider {
		.slider {
			.slides {
				li {
					@for $i from 1 through 10 {
						&#slide-#{$i} img {
							background-image: url("/gfx/home-slider/med/slide-#{$i}.jpg") !important;
						}
					}

					.caption .slide-header {
						font-size: 1.8rem;
						width: 70%;
					}
					.caption .slide-sub {
						width: 70% !important;
						font-size: 1.2rem;
					}
				}
			}
		}
	}
}

.social-logo {
	position: relative;
	top: 5px;
}

.adminedit {
	position: fixed;
	top: 150px;
	right: 20px;
	background-color: $emphasis-color;
	z-index: 100;
	a {
		color: white;
	}
}

.backdrop {
	background: rgba(0, 0, 47, 0.85);
}

.tooltip-panel {
	width: 350px;
}

.hover-textshadow {
	transition: text-shadow 0.3s;
}

.hover-textshadow:hover {
	text-shadow: 1px 1px 3px $thirdy-color;
}

.toc-wrapper {
	margin-top: $navbar-height + $line-height;
	padding: 0px;

	&.pin-top {
		margin-top: 0px;
	}
	&.pin-bottom {
		margin-top: 240px;
	}
}

table.features {
	td,
	th {
		text-align: center;
	}

	td:nth-child(even) {
		background-color: $alternate-color;
	}

	td:nth-child(1),
	th:nth-child(1) {
		text-align: left;
	}
}

table.cart {
	input[type="text"] {
		height: $line-height;
		margin: 0px;
	}

	td.right,
	th.right {
		text-align: right;
	}
}

.cart-summary {
	td,
	th {
		padding: 0px 0px;
	}
}

.toast {
	background-color: $primary-color;
	i {
		position: relative;
		left: -10px;
	}
}

#toast-container > .error {
	background-color: $error-color !important;
}
.circle {
	width: 200px;
	margin: 0px;
	display: inline-block;
	position: relative;
	text-align: center;
	line-height: 1.2;
}
.circle span {
	position: relative;
	top: 10px;
}

.circle-strong {
	position: absolute !important;
	top: 30px !important;
	width: 100%;
	left: 0px;
	font-weight: 200;
	text-align: center;
	line-height: 0.8 * $line-height;
	font-size: 1.2rem;
	display: none;
}



.countCircle, .monitorCircle {
	min-height:190px !important;
}

#userform,
contactform {
	overflow: hidden;
}
iframe {
	width: 100%;
}
.shop-wizard-nav {
	height: auto !important;
	line-height: 30px;
	padding: 15px 0;
}
table.wide {
	min-width: 991px;
	tr {
		td {
			border: #c3c3ff 1px solid;
			background-color: #e3e3ff;
			vertical-align: middle;
			text-align: left;
			padding: 4px;
			border-radius: 0;
		}
	}
}
table.wide tr:nth-child(1) td {
	background: #bfc3ff;
}
.form-ta textarea {
	border: 1px solid #a7aeff;
	padding: 2px;
}
textarea {
	border: 0;
	border-bottom: 1px solid #9e9e9e;
}
.tdodd [type="checkbox"]:not(:checked),
.tdodd [type="checkbox"]:checked {
	position: relative;
	left: auto;
	opacity: 1;
}
#editor {
	height: 400px !important;
}
.imgleft {
	float: left;
	margin-right: 15px;
	margin-bottom: 15px;
}

.floatleft50 {
	width: 50%;
	float: left;
	margin-right: 15px;
	margin-left: 0px;
	margin-top: 5px;
	margin-bottom: 15px;	
	max-width:100%;

}
 
.floatright50 {
	width: 50%;
	float: right;
	margin-left: 15px;
	margin-bottom: 15px;
}

.scrollSecMain {
	margin-top: 0px;
	padding-top: 0px;
	.scrollerMain{
		.singleSlide {
			position: relative;
			display: flex !important;
			align-items: center;
			justify-content: center;
			height: 100px;
			background: #fff;
			margin: 0 10px 50px;
			padding: 0;
			img{
				display: block;
				max-width: 100%;
			}
		}
		.slick-arrow {
			position: absolute;
			z-index: 9;
			top: 50%;
			left: 0;
			font-size: 0;
			border: 0;
			padding: 0;
			width: 30px;
			height: 50px;
			margin-top: -25px;
			background: $alternate-color;
			box-shadow: 0 0 5px #ddd;
			opacity: 0.5;
			transition: opacity 0.5s;
			&:hover {
				opacity: 1 !important;
				transition: opacity 0.5s;
			}
		}
		.slick-next.slick-arrow {
			left: auto;
			right: 0;
		}
		.slick-next.slick-arrow::after {
			content: '\203A';
			font-family: 'fontAwesome';
			font-size: 30px;
			color: $primary-color;
			line-height: 44px;
			top: 0;
			left: 0;
			position: absolute;
			width: 100%;
			height: 100%;
			text-align: center;
		}
		.slick-prev.slick-arrow::after {
			content: '\2039';
			font-family: 'fontAwesome';
			font-size: 30px;
			color: $primary-color;
			line-height: 44px;
			top: 0;
			left: 0;
			position: absolute;
			width: 100%;
			height: 100%;
			text-align: center;
		}
	}
}

@media screen and (max-width: 1020px) {
	.user-form-container {
		.login-form {
			width: 100%;
			background: #fff;
			padding: 20px;
			margin: 10px 0;
			color: $primary-color;
			.shop-upload-form {
				margin-top: 0;
			}
			h3 {
				margin: 0 0 10px;
			}
		}
	}
	.home-slider {
		.slider {
			.slides {
				li {
					img {
						background-size: cover;
					}
					.caption {
						.slide-header {
							font-size: 1.3rem;
							line-height: 26px;
						}
						.slide-sub {
							font-size: 1.1rem;
							line-height: 26px;
						}
					}
				}
			}
		}
	}
	.top-news-section.rdTopNews {
		padding-bottom: 30px;
		h1 {
			text-align: center;
		}
		.col.l8 {
			width: 100%;
			.valign-wrapper {
				display: flex;
				align-items: center;
				justify-content: space-around;
			}
		}
	}
	.footer-copyright {
		.footer-copyright-menu.inline-list {
			width: 100%;
			text-align: center;
			ul.inline-list {
				li {
					padding: 0 5px;
				}
			}
		}
		.footer-copyright-text {
			width: 100%;
			text-align: center;
			margin-top: 6px;
		}
	}

	.planList {
		.card-title.card-box {
			width: 100%;
		}
	}
}

@media screen and (max-width: 767px) {
	.valign-wrapper {
		display: block;
	}
	.top-news-section.rdTopNews {
		.col.l8 {
			.valign-wrapper {
				display: block;
				.circle {
					width: 100%;
					margin-bottom: 35px;
				}
			}
		}
	}
	.sliderWrap {
		overflow: auto;
		max-width: 540px;
		width: 100%;
		margin: 0 auto;
	}
	.planList {
		.card.hoverable.imagecard {
			width: 100%;
			position: relative;
			float: left;
			z-index: 1;
		}
		.card-title.card-box {
			position: relative;
			float: left;
			top: auto;
			left: auto;
			z-index: 1;
		}
		.card-content {
			width: 100%;
			position: relative;
			float: left;
			z-index: 1;
		}
		.card-action {
			width: 100%;
			position: relative;
			float: left;
			z-index: 1;
		}
	}
	.table-responsive {
		width: 100%;
		position: relative;
		overflow-x: auto;
	}
	.tooltip-panel {
		width: 200px;
		p {
			font-size: 13px;
			margin: 10px 0;
		}
	}
	.input-field {
		label {
			font-size: 0.81rem;
		}
	}
	td,
	th {
		padding: 10px;
		font-size: 15px;
	}
	.brTable td {
		display: block;
	}
	input {
		height: 2em !important;
	}
}

@media screen and (max-width: 640px) {
	.sliderWrap {
		max-width: 280px;
	}
	.planList {
		.card-action {
			a {
				display: block;
			}
		}
	}
	.card.horizontal.smDb {
		display: block;
	}
	img.smBlockImg {
		float: none !important;
		margin: 0 auto !important;
		display: block;
		margin-bottom: 10px !important;
		max-width: 100%;
	}
	img.blockImg {
		float: none !important;
		margin: 0 auto !important;
		display: block;
		margin-bottom: 10px !important;
		max-width: 100%;
	}
	iframe {
		width: 100%;
		height: auto;
	}
	.smHauto {
		height: 200px !important;
		.card-content {
			p {
				margin-bottom: 15px;
			}
		}
	}
}

.cookie-consent 
{
	z-index: 1000;
	position: fixed;
	width: 470px;
	height: 360px;
	
	left: 30px;
	bottom: 30px;

	background-color: $alternate-color;
	padding: 20px;
	p {
		font-size: 0.9rem;
		line-height: $line-height * 0.9;
		margin: 5px;
	}
	.sub {
			font-size: 0.75rem;
			margin-top: 10px;

	}
	.header {
		font-size: 1.3rem;
		line-height: $line-height * 0.9;
		margin-top: 10px;
		margin-bottom: 20px;
		a {
			font-size:0.9rem;
			margin-left:20px;
		}
	}
	.btn {
		font-size: 0.9rem;
		text-transform: none;
		height: 1.8rem;
		line-height: 1.8rem;
		margin:6px;
		width: 400px;
		
	}
}

.cookie-consent.serious {
	top: 50% !important;
	left: 50% !important;
	margin-top: -235px !important;
	margin-left: -200px !important;
}

.cookie-modal {
  position: fixed;
  top:0;
  left:0;
  height:100%;
  width: 100%;
  z-index:999;
  background-color: #000;
  opacity: 0.5;
}



