@charset "UTF-8";

// Homepage New Css
.menu-list.ndMenu{
	li{
		position: relative;
		.up-arrow {
			position:absolute;
			left:50%;
			margin-left: -85px;
			bottom: -8px;
			visibility: hidden;
			opacity: 0;
			z-index:9999;
			height: 20px;
			img {
				max-width: 175px;
			}
		}
		&:hover .up-arrow {
			visibility: visible !important;
			opacity: 1;
		}

		a{
			padding-left: 25px;
			padding-right: 25px;
			margin: 0 0 0 0;
			margin-bottom: 0px;
			position: relative;
		}
		.structure {
			position: absolute;
			top: 92px;
			background: white;
			border-radius: 5px;
			box-shadow: 3px 3px 15px #000;
			padding: 20px 20px;
			visibility: hidden;
			opacity: 0;
			z-index: 99;
			.strcut1Top {
				width: 100%;
				position: relative;
				float: left;
				a{
					&::after{display: none;}
				}
				.stBlock {
					width: 50%;
					position: relative;
					float: left;
					display: flex;
					align-items: flex-start;
					justify-content: flex-start;
					line-height: 17px;
					padding: 14px 0;
					.img {
						width: 100%;
						max-width: 75px;
						padding-right: 15px;
					}
					.desc {
						a {
							color: $secondary-color;
							font-size: 15px;
							font-weight: 700;
							margin: 0 !important;
							padding: 0;
						}
						a:hover {
							text-decoration: underline;
							background: none;
						}						
					}
				}
			}
			.strcut1Top.structLft {
				width: 54%;
				float: left;
				padding-right: 15px;
				.stBlock{
					width: 100%;
					.img {
						width: 100%;
						max-width: 75px;
						padding-right: 15px;
					}
				}
				&::after {
					content: '';
					position: absolute;
					top: 0;
					right: 0;
					width: 1px;
					height: 100%;
					background: $secondary-color;
				}
			}
			.strcut1Top.structRgt {
				width: 46%;
				float: left;
				padding-left: 15px;
				.stBlock{
					width: 100%;
					display: block;
					.img {
						width: 100%;
						max-width: 90px;
						padding: 0 0 0 0;
						margin-bottom: 20px;
						margin-left: auto;
						margin-right: auto;
					}
				}				
			}
			p {
				color: $gray-text-color;
				font-size: 14px;
				line-height: 20px;
				margin: 5px 0 0;
			}
			.stBlock.pt-0 {
				padding-top: 0 !important;
			}
			.strcut1Btm {
				width: 100%;
				position: relative;
				float: left;
				padding: 10px 0 0;
				border-top: 1px solid $secondary-color;
				margin: 10px 0 0;
				text-align: center;				
				p {
					color: $secondary-color;
					font-size: 17px;
					line-height: 20px;
					margin: 5px 0 0;
					font-weight: 400;
					max-width: 480px;
					margin: 0 auto;
					a{
						color: $secondary-color;
					}
				}
			}			
		}
		.structure.structure2.structure3{
			.strcut1Top.structLft {
				width: 50% !important;
			}
			.strcut1Top.structLft:nth-child(2){
				padding-right: 0;
				padding-left: 20px;
			}
			.strcut1Top.structLft:nth-child(2)::after{
				display: none;
			}
			.strcut1Top.structRgt {
				width: 50% !important;
			}
			.stBlock{
				.img {
					width: 100%;
					max-width: 75px;
					padding-right: 15px;
				}
			}

		}
		.waves-effect.waves-light.btn {
			margin: 0;
		}
		.structure.structure1 {
			width: 630px;
			left: 50%;
			margin-left: -315px;
		}
		.structure.structure2 {
			width: 590px;
			right: 0;
		}
		.structure.structure1.nth2 {
			width: 850px;
			left: auto;
			right: -240px;
			.strcut1Top {
				width: 70%;
				float: left;
			}
			.strcut1Top.structRgt{
				width: 30% !important;
				float: left;
				&::after {
					content: '';
					position: absolute;
					top: 0;
					left: -2px;
					width: 1px;
					height: 100%;
					background: $secondary-color;
					
				}
			}
		}
		.structure.structure2.nth3 {
			right: -100px;
		}
		.structure.structure2.nth4 {
			right: -56px;
			width:740px;

		}
		.structure.structure2.nth5 {
			right: 0px;
			width: 740px;
			.structLft {
				width: 45%;
			}
			.structRgt {
				width: 55%;
			}
		}
	}
	li.hovered > a::after {
		visibility: visible !important;
		opacity: 1 !important;
	}
	li.hovered > .up-arrow {
		visibility: visible !important;
		opacity: 1 !important;
	}
	img {
		display: block;
		margin: 0;
		width: auto;
		max-width: 100%;
	}
}
.menu-list.ndMenu li:hover .structure{visibility: visible; opacity: 1;}