html {
  font-weight: 300;
  color: $off-black;
  font-size: $font-size;
  line-height: $line-height;
}

// Header Styles
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  font-weight: inherit;
}

h1 {
  font-size: 1.8rem;
  font-weight: 300;
}

h2, .header2 {
  font-size: 1.6rem;
  font-weight: 300;
  line-height: $line-height * 1.5;
  margin-top: $line-height;
  margin-bottom: $line-height;
}

h3, .header3 {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: $line-height;
  margin-top: $line-height;
  margin-bottom: $line-height;
}

h4 {
  font-size: 1rem;
  line-height: $line-height;
  margin-top: $line-height;
  margin-bottom: $line-height;
  text-transform: uppercase;
}

@media #{$medium-and-up} {
  h2 {
    line-height: $line-height * 1.75;
    margin-top: $line-height;
    margin-bottom: $line-height;
  }
}

.gray-text {
  color: $gray-text-color !important;
}

.small-text {
  font-size: $font-size * 0.9;
}
