@charset "UTF-8";

@import "components/plagaware_variables";

// Materializecss
@import "materialize/materialize";

@import "components/plagaware_common";
@import "components/plagaware_global";
@import "components/plagaware_grid";
@import "components/plagaware_navbar";
@import "components/plagaware_typography";
@import "components/plagaware_shop";
@import "components/plagaware_home";
